import React from "react";
import {Link} from "gatsby";
import meImg from "../../assets/custom-style/images/welcome2.webp";

const Welcome = () => (
    <section className={"welcome "}>

        <div className="container pb-5">
            <div className="row d-flex align-items-center">
                <div className="col-12 col-md-6">
                    <h1 className={"display-1 text-secondary fw-bold"}>Petr Balvín</h1>

                    <h2 className={"display-3 text-primary fw-bold"}>
                        tvořím precizní weby
                    </h2>
                    <p className={"lead"}>Smysluplný obsah, technické zpracování<br/>
                        a rychlost webu jsou pro mě na prvním místě. Přes to nejede vlak! </p>
                </div>
                <div className="col-12 col-md-6 text-end">
                 {/*   <img src={meImg} className={"meImg shadow-lg"}/>*/}
                </div>
            </div>

        </div>
    </section>

);

export default Welcome

