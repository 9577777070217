import React from "react"
import Layout from "../components/Layout/Layout"
import Welcome from "../components/Sections/Welcome";
import Services from "../components/Sections/Services";

export default function Home() {
    return (
        <Layout>
            <Welcome/>
            <Services/>

        </Layout>
    );
}